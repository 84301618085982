.scroll-box {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.scroll-box::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

html {
  height: 100dvh;
  scrollbar-gutter: stable;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
  white-space: pre-line;
}

td {
  padding: 5px;
}

@font-face {
  font-family: 'Godo';
  font-style: normal;
  font-weight: 400;
  src: url('//cdn.jsdelivr.net/korean-webfonts/1/corps/godo/Godo/GodoM.woff2')
      format('woff2'),
    url('//cdn.jsdelivr.net/korean-webfonts/1/corps/godo/Godo/GodoM.woff')
      format('woff');
}

@font-face {
  font-family: 'Godo';
  font-style: normal;
  font-weight: 700;
  src: url('//cdn.jsdelivr.net/korean-webfonts/1/corps/godo/Godo/GodoB.woff2')
      format('woff2'),
    url('//cdn.jsdelivr.net/korean-webfonts/1/corps/godo/Godo/GodoB.woff')
      format('woff');
}

.godo * {
  font-family: 'Godo', sans-serif;
}
